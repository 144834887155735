
import logo5 from "../../assets/images/Our Partners/logo5.png"
import logo11 from "../../assets/images/Our Partners/gicsl.png"
import logo12 from "../../assets/images/Our Partners/WhatsApp Image 2024-04-19 at 20.57.52_ab168ce7.jpg"
import logo13 from "../../assets/images/Our Partners/WhatsApp Image 2024-04-19 at 20.57.52_b9177632.jpg"
import logo15 from "../../assets/images/Our Partners/WhatsApp Image 2024-04-19 at 20.57.53_28999bd2.jpg"
import logo16 from "../../assets/images/Our Partners/WhatsApp Image 2024-04-19 at 20.57.53_38e9066d.jpg"
import logo17 from "../../assets/images/Our Partners/WhatsApp Image 2024-04-19 at 20.57.53_8677ec9b.jpg"
import logo19 from "../../assets/images/Our Partners/WhatsApp Image 2024-04-19 at 20.57.55_84e1fe1c.jpg"


const OurPartners = () => {

    return<>
        <div className="partnerWrapper">
            <div className="partnerOverlay">
                <h2>OUR PARTNERS</h2>
                <div className="partnerCardWrapper partnerCardWrapper">
                    <img src={logo11} alt="" className="lastLogo"/>
                    <img src={logo12} alt="" />
                    <img src={logo13} alt="" />
                    <img src={logo5} alt="" />
                    <img src={logo15} alt="" />
                    <img src={logo19} alt="" />
                    <img src={logo16} alt="" />
                    <img src={logo17} alt="" />
                </div>
            </div>
        </div>

    </>

}

export default OurPartners