import logo1 from "../../assets/images/Our Partners/logo 1.png"
import logo2 from "../../assets/images/Our Partners/logo 2.png"
import logo3 from "../../assets/images/Our Partners/logo 3.png"
import logo4 from "../../assets/images/Our Partners/logo4.png"
import logo6 from "../../assets/images/Our Partners/logo6.png"
import logo7 from "../../assets/images/Our Partners/logo7.png"
import logo8 from "../../assets/images/Our Partners/logo8.png"
import logo9 from "../../assets/images/Our Partners/logo9.png"
import logo18 from "../../assets/images/Our Partners/WhatsApp Image 2024-04-19 at 20.57.53_ab0f08b5.jpg"

const OurClient = () => {

    return<>
        <div className="clientsWrapper">
            <div className="clientsOverlay">
                <h2>OUR CLIENTS</h2>
                <div className="clientsCardWrapper">
                    <img src={logo1} alt="" />
                    <img src={logo2} alt="" />
                    <img src={logo3} alt="" />
                    <img src={logo4} alt="" />
                    <img src={logo6} alt="" />
                    <img src={logo7} alt="" />
                    <img src={logo8} alt="" />
                    <img src={logo9} alt="" />
                    <img src={logo18} alt="" />
                </div>
            </div>
        </div>
    </>

}

export default OurClient